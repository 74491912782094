import Menu from './main/components/Menu';
import Foot from './main/components/Foot';

// eslint-disable-next-line
import Body from './main/components/NotFound';
//import Body from './privat/components/Links';


import './App.css';

function App() {
  return (
    <div className="App">
      <Menu/>
      <div className='App-body'>
        <Body/>
      </div>
      <Foot/>
    </div>
  );
}

export default App;

import './Foot.css';

function Foot() {

    return (
        <footer className='foot-section'>
	        <a href='/'>@letsstein.de</a> 2022 All rights reserved
	    </footer>
    );
}

export default Foot;
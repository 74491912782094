/**
 * SVG minifyer: https://www.svgminify.com/
 * SVG to JSX converter:
 *      https://react-svgr.com/playground/
 *      https://svg2jsx.com/
 */

import {ReactComponent as NotFoundImg} from '../images/not-found.svg';
import './NotFound.css'

function NotFound () {
    return (
        <div className='not-found'>
            <NotFoundImg/>
        </div>
    );
}

export default NotFound;
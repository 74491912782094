/**
 * SVG minifyer: https://www.svgminify.com/
 * SVG to JSX converter:
 *      https://react-svgr.com/playground/
 *      https://svg2jsx.com/
 */

//import {ReactComponent as DownloadIcon} from '../images/cloud-download.svg';
import {ReactComponent as CloudIcon} from '../images/cloud.svg';
import {ReactComponent as Arrow} from '../images/arrow.svg';
import LogoText from './LogoText';
import './Menu.css';

function Menu() {

    return (
        <header className='menu-section'>
            <a href="https://letsstein.de">
                <LogoText/>
            </a>
            <div>
                <a href="https://cloud.letsstein.de" className='menu-entry'>
                    <CloudIcon/>
                    <label>Cloud</label>
                </a>
                <div className='menu-entry'>
                    <Arrow className='menu-rotate'/>
                    <label>Other</label>
                    <nav>
                        <a href="https://letsstein.de/polytopia">Polytopia</a>
                        <a href="https://letsstein.de/civ">Civilization</a>
                        <a href="https://privat.letsstein.de">Private</a>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Menu;
import '../font/ALGER.css';
import './LogoText.css';

function LogoText() {

    return (
        <div className='logotext'>
            <div>
                Lets<i>Stein</i>
            </div>
            <div>
                <mark/>
                <i>Gaming Server</i>
            </div>
        </div>
    );
}

export default LogoText;